import Keycloak from "keycloak-js";
import { action, computed, observable } from "mobx";
import { notificationModel } from "../common/component/notifications/notification-model";
import { qzManager } from "../front-office/qz/qz-manager";
import { authenticationService } from "../services/authentication";
import { configurationService } from "../services/configuration";
import { profileService } from "../services/profile";
import { AppConfiguration, IAppConfiguration, FeatureToggle } from "./app-configuration";
import { Permission } from "./permission";
import { Profile } from "./profile";
import { Settings } from "./settings";
import { SettingsManager } from "./settings-manager";
import { SettingsProvider } from "./settings-provider";
import { User } from "./user";
import { WarehouseContext } from "./warehouse-context";

class Context {

    @observable private _settings: Settings = (new SettingsProvider()).defaultSettings();
    @observable private _currentUser: User = new User();
    @observable private _keykloak: Keycloak.KeycloakInstance<"native">;
    @observable private _configuration: AppConfiguration;
    @observable private _warehouseContext: WarehouseContext | undefined;

    private _applicationName: string;
    private readonly _settingsManager = new SettingsManager();

    public qzTimerId: any;

    @computed public get settings() {
        return this._settings;
    }
    @computed public get currentUser() {
        return this._currentUser;
    }
    @computed public get isAuthenticated() {
        return this._currentUser && this._currentUser.token;
    }
    @computed public get isSuperAdministrator() {
        return this._currentUser.isSuperAdministrator;
    }
    @computed public get isAdministrator() {
        return this._currentUser.isAdministrator;
    }
    @computed public get isSupplier() {
        return this._currentUser.isSupplier;
    }
    @computed public get warehouseContext() {
        return this._warehouseContext;
    }
    @computed public get warehouseCode() {
        return this._warehouseContext?.code ?? "";
    }

    @computed public get environmentName() {
        return this._configuration.environmentName;
    }

    @computed public get isImpersonatedUser() {
        return this._currentUser.impersonationToken !== undefined;
    }

    public hasPermission(permission: Permission) {
        return this._currentUser.permissions.has(permission);
    }

    public set applicationName(applicationName: string) {
        this._applicationName = applicationName;
    }

    public get applicationName() {
        return this._applicationName;
    }

    @action
    public setCurrentUser(user: User) {
        this._currentUser = user;
    }

    @action
    public initSettings(profile: Profile) {
        this._settings = (new SettingsProvider()).forUser(profile);
    }

    @action
    public initConfiguration(configuration: IAppConfiguration) {
        this._configuration = new AppConfiguration(configuration);
    }

    @action
    public async updatePrinterType(printerType: string) {
        this.resetSettings(await this._settingsManager.update(context.settings, "printerType", printerType));
    }

    @action
    public async updatePrinterPaperFormat(printerPaperFormat: string) {
        this.resetSettings(await this._settingsManager.update(context.settings, "printerPaperFormat", printerPaperFormat));
    }

    @action
    public async updateIsDownload(isDownload: string) {
        this.resetSettings(await this._settingsManager.update(context.settings, "isDownload", isDownload));
    }

    @action
    public async updatePrinterName(printerName: string) {
        this.resetSettings(await this._settingsManager.update(context.settings, "printerName", printerName));
    }

    @action
    public async updateLocale(locale: string) {
        this.resetSettings(await this._settingsManager.update(context.settings, "lang", locale));
    }

    @action
    public async updateMute(muted: string) {
        this.resetSettings(await this._settingsManager.update(context.settings, "muted", muted));
    }

    @action
    public async updateEmail(email: string) {
        this.resetSettings(await this._settingsManager.update(context.settings, "contactEmail", email));

        return context.settings.contactEmail === email;
    }

    @action
    public async updateWebhookUrl(webhookUrl: string) {
        this.resetSettings(await this._settingsManager.update(context.settings, "webhookUrl", webhookUrl));

        return context.settings.webhookUrl === webhookUrl;
    }

    @action
    private resetSettings(settings: Settings) {
        this._settings = settings;
    }

    @action
    public updateCurrentUserSpareToken(token: string) {
        this._currentUser.spareToken = token;
    }

    @action
    public updateCurrentUserSsoToken(ssoToken: string) {
        this._currentUser.ssoToken = ssoToken;
    }

    @action
    public switchCurrentUserTokens() {
        this._currentUser.useSpareToken();
        localStorage.setItem("ds_user_session", JSON.stringify(this.currentUser));
    }

    public async initSso() {
        this.initConfiguration(await configurationService.load());

        this._keykloak = Keycloak({
            url: this._configuration.sso!.authServerUrl,
            realm: this._configuration.sso!.realm,
            clientId: this._configuration.sso!.resource,
        });

        return await this._keykloak.init({
            onLoad: this._currentUser.authScheme === "sso" ? "check-sso" : undefined,
            promiseType: "native",
            checkLoginIframe: false,
        }).then(async (authenticated: any) => {
            if (authenticated && this._keykloak.token) {
                const userName = (this._keykloak.tokenParsed as any).preferred_username;
                try {
                    const tokenResponse = await authenticationService.tokenForSsoUser(this._keykloak.token);
                    const newUser = new User(userName, tokenResponse.access_token, this._keykloak.token, "sso");
                    this.setCurrentUser(newUser);
                    await authenticationService.whoami();
                    this.saveCurrentUserInLocalStorage();
                } catch (e) {
                    notificationModel.addErrorMessage("error.authentication.userNotRegistered");
                    throw new Error("User not registered");
                }
            }
        })
        .catch(() => this.reset());
    }

    public initUserSession() {
        const storedSession = localStorage.getItem("ds_user_session");
        const impersonationToken = sessionStorage.getItem("ds_impersonation_token");

        if (storedSession) {
            const user = User.unserialize(storedSession);
            user.impersonationToken = impersonationToken ?? undefined;
            this.setCurrentUser(user);
        }

        return null;
    }

    public async initAuthenticatedContextAsync(firstLogin: boolean = false) {
        if (!this.isAuthenticated) {
            return;
        }
        try {
            await Promise.all([
                profileService.load().then(profile => this.initSettings(profile)),
                configurationService.load().then(configuration => this.initConfiguration(configuration)),
            ]);
            this.initializeWarehouseContext();

            qzManager.qzHealthTimer();

            // Get spare token to automatic renewal
            if (firstLogin) {
                const response = await authenticationService.token();
                this.updateCurrentUserSpareToken(response.access_token);
            }

            this.saveCurrentUserInLocalStorage();
        } catch (e) {
            this.reset();
        }
    }

    @action
    private initializeWarehouseContext() {
        this._warehouseContext = WarehouseContext.initializeForUser(this.currentUser);
    }

    @action
    public updateAdministratorWarehouseContext(warehouse?: string, country?: string) {
        if (warehouse === undefined) {
            this._warehouseContext = undefined;

            return;
        }

        if (!this._currentUser.isSuperAdministrator) {
            throw new Error("Permission denied");
        }

        this._warehouseContext = new WarehouseContext(warehouse, country ?? "UNKNOWN");
    }

    public saveImpersonationTokenInSessionStorage(impersonationToken: string): void {
        sessionStorage.setItem("ds_impersonation_token", impersonationToken);
    }

    private saveCurrentUserInLocalStorage(): void {
        localStorage.setItem("ds_user_session", this._currentUser.serialize());
    }

    @action
    public clearImpersonation() {
        sessionStorage.removeItem("ds_impersonation_token");
    }

    @action
    public reset() {
        localStorage.removeItem("ds_user_session");
        this.clearImpersonation();
        this._currentUser =  new User();
        clearInterval(this.qzTimerId);
    }

    public isFeatureToggleEnabled(featureToggle: FeatureToggle): boolean {
        return this._configuration.isFeatureToggleEnabled(featureToggle);
    }

    public async refreshSsoTokenIfSsoEnabled(): Promise<void> {
        if (!this.isSsoActive() || this._currentUser.authScheme !== "sso") {
            return Promise.resolve();
        }

        const ssoToken = await new Promise<string | undefined>(resolve => {
            this._keykloak.updateToken(5)
                .then((refreshed: boolean) => {
                if (refreshed && this._keykloak.token) {
                    this.updateCurrentUserSsoToken(this._keykloak.token);
                    resolve(this._keykloak.token);
                }
                resolve(undefined);
            }).catch(resolve);
        });

        if (ssoToken) {
            const tokenResponse = await authenticationService.tokenForSsoUser(ssoToken);
            this.updateCurrentUserSpareToken(tokenResponse.access_token);
            this.switchCurrentUserTokens();
        }
    }

    public getSsoLoginUrl(redirectPath: string): string {
        return this._keykloak.createLoginUrl({
            redirectUri: window.location.origin + redirectPath,
        });
    }

    public isSsoActive(): boolean {
        return this._keykloak !== undefined;
    }
}

export const context = new Context();
