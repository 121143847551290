import { PalletsListSubItem } from "./pallets-list-sub-item";
import { Pallet } from "../../../model/pallet";
import { action } from "mobx";
import { orderBy } from "lodash";
import { domain, label } from "../../../../common/dto/dto-annotation";
import { weight } from "../../../../domain/weight";
import { quantity } from "../../../../domain/quantity";
import { weightKgs } from "../../../../domain/weight-kgs";
import moment from "moment";
import { localDate } from "../../../../domain/local-date";
import { PalletType } from "../../../constants/constants";

export class PalletsListItem {
    private readonly _id: string;
    private readonly _code: string;
    private readonly _carrier: string;
    private readonly _country: string;
    private readonly _parcelCount: number;
    private readonly _weight: number;
    private readonly _maxParcelWeight: number;
    private readonly _maxWeight: number;
    private readonly _isLabelPrinted: boolean;
    private _details: PalletsListSubItem[];
    private readonly _pickingDate: moment.Moment | null;
    private readonly _type: PalletType;

    public constructor(pallet: Pallet) {
        this._id = pallet.id.toString();
        this._code = pallet.code;
        this._carrier = pallet.carrier;
        this._country = pallet.countryGroup;
        this._parcelCount = pallet.parcelsCount;
        this._weight = pallet.parcelsWeight;
        this._maxParcelWeight = pallet.maxParcelWeight;
        this._maxWeight = pallet.maxWeight;
        this._isLabelPrinted = pallet.isLabelPrinted;
        this._details = pallet.parcels.map(p => new PalletsListSubItem(p));
        this._pickingDate = pallet.pickingDate;
        this._type = pallet.type;
    }

    @label("model.pallet.id")
    public get id(): string {
        return this._id;
    }

    @label("model.pallet.id")
    public get palletId(): string {
        return this._id;
    }

    @label("model.pallet.code")
    public get code(): string {
        return this._code;
    }

    @label("model.pallet.code")
    public get codeNumeric(): number {
        return Number(this._code.replace("DS", ""));
    }

    @label("model.pallet.id")
    public get parcelId(): string {
        return this._id;
    }

    @label("model.pallet.carrier")
    public get carrier(): string {
        return this._carrier;
    }

    @label("model.pallet.country")
    public get country(): string {
        return this._country;
    }

    @label("model.pallet.parcelCount")
    @domain(quantity)
    public get parcelCount(): number {
        return this._parcelCount;
    }

    @label("model.pallet.weight")
    @domain(weightKgs)
    public get weight(): number {
        return this._weight;
    }

    @domain(weightKgs)
    public get maxParcelWeight(): number {
        return this._maxParcelWeight;
    }

    @label("model.pallet.maxWeight")
    @domain(weightKgs)
    public get maxWeight(): number {
        return this._maxWeight;
    }

    @label("model.pallet.isLabelPrinted")
    @domain(weight)
    public get isLabelPrinted(): boolean {
        return this._isLabelPrinted;
    }

    @label("model.pallet.pickingDate")
    @domain(localDate)
    public get pickingDate(): moment.Moment | null {
        return this._pickingDate;
    }

    public get type(): PalletType {
        return this._type;
    }

    public get details(): PalletsListSubItem[] {
        return this._details;
    }

    public getDetailByLocator(locator: string): PalletsListSubItem | undefined {
        return this._details.find(parcel => parcel.parcelId.toString() === locator || parcel.parcelTracker === locator);
    }

    public hasParcelWithLocator(locator: string) {
        return this.getDetailByLocator(locator) !== undefined;
    }

    @action
    public sortDetailsBy(subProperty: keyof PalletsListSubItem, sortOrder: "asc" | "desc") {
        this._details = orderBy(this._details, subProperty, sortOrder);
    }
}
